const mutations = {
  UPDATE_PAGE(state: any, val: any) {
    state.page = val;
  },
  UPDATE_TITLE(state: any, val: any) {
    state.title = val;
  },
  UPDATE_ICON(state: any, val: any) {
    state.icon = val;
  },
  UPDATE_LOADING(state: any, val: any) {
    state.loading = val;
  },
  UPDATE_THEME(state: any, val: any) {
    state.themeType = val;
  },
  UPDATE_CHART_TYPE(state: any, val: any) {
    state.chartType = val;
  },
  UPDATE_DLAYOUT(state: any, val: any) {
    state.dlayout = val;
  },
  UPDATE_FAVORITES(state: any, val: any) {
    state.favorites = val;
  },
  UPDATE_RESIZE(state: any, val: any) {
    state.resize = val;
  },
  UPDATE_RANGE_STEP(state: any, val: any) {
    state.rangeStep = val;
  },
  UPDATE_FILTER_KUNDEN(state: any, val: any) {
    state.filterKunden = val;
  },
  UPDATE_SELECTED_YEAR(state: any, year: any) {
    state.selectedYear = year;
  },
  UPDATE_PLAN_YEAR(state: any, year: any) {
    state.planYear = year;
  },
  UPDATE_SELECTED_CLIENT(state: any, client: any) {
    state.selectedClient = client;
  },
  UPDATE_SELECTED_AGENCY(state: any, agency: any) {
    state.selectedAgency = agency;
  },
  UPDATE_BI_SELECTED_AGENCY(state: any, agency: any) {
    state.selectedBiAgency = agency;
  },
  UPDATE_YEARS(state: any, years: any) {
    state.years = years;
  },
  UPDATE_CLIENTS(state: any, clients: any) {
    state.clients = clients;
  },
  UPDATE_AGENCIES(state: any, agencies: any) {
    state.agencies = agencies;
  },
  UPDATE_CURRENT_CALCS(state: any, calcs: any) {
    state.currentCalcs = { ...calcs };
  },
  UPDATE_CURRENT_YEAR_SUMS(state: any, yearSums: any) {
    state.currentYearSums = yearSums;
  },
  UPDATE_PREVIOUS_CALCS(state: any, calcs: any) {
    state.previousCalcs = calcs;
  },
  UPDATE_PREVIOUS_YEAR_SUMS(state: any, yearSums: any) {
    state.previousYearSums = yearSums;
  },
  UPDATE_CURRENT_SUPPLIERS(state: any, suppliers: any) {
    state.currentSuppliers = suppliers;
  },
  UPDATE_UMS_DETAIL_KUNDEN(state: any, list: any) {
    state.umsDetailKunden = list;
  },
  UPDATE_PREV_UMS_DETAIL_KUNDEN(state: any, list: any) {
    state.prevUmsDetailKunden = list;
  },
  UPDATE_MATERIAL_DETAIL(state: any, list: any) {
    state.materialDetail = list;
  },
  UPDATE_PREV_MATERIAL_DETAIL(state: any, list: any) {
    state.prevMaterialDetail = list;
  },
  UPDATE_LIEFERANTEN_TOP(state: any, list: any) {
    state.lieferantenTop = list;
  },
  UPDATE_OPOS_DEB(state: any, data: any) {
    state.oposDeb = data;
  },
  UPDATE_OPOS_KRED(state: any, data: any) {
    state.oposKred = data;
  },
  UPDATE_OPOS_FILTERS(state: any, data: any) {
    state.oposFilters = data;
  },
  UPDATE_OPOS_CURVES(state: any, data: any) {
    state.oposCurves = data;
  },
  UPDATE_BILANZ_TEMPLATES(state: any, data: any) {
    state.bilanzTemplates = data;
  },
  UPDATE_BILANZ_TEMPLATE_DATA(state: any, data: any) {
    state.bilanzTemplateData = data;
  },
  UPDATE_PLAN_SELECTED(state: any, data: any) {
    state.planSelected = data;
  },
  UPDATE_PLAN_DATA(state: any, data: any) {
    state.planData = data;
  },
  UPDATE_PLAN_BASE_DATA(state: any, data: any) {
    state.planBaseData = data;
  },
  UPDATE_PLAN_VERSION(state: any, data: any) {
    state.planVersion = data;
  },
  UPDATE_SKR_TEMPLATES(state: any, data: any) {
    state.skrTemplates = data;
  },
  UPDATE_SKR_TEMPLATE_DATA(state: any, data: any) {
    state.skrTemplateData = [...data];
  },
  UPDATE_IS_Calc_CALLED(state: any, flag: boolean) {
    state.isCalcCalled = flag;
  },
  UPDATE_IS_Prev_Calc_CALLED(state: any, flag: boolean) {
    state.isPrevCalcCalled = flag;
  },
  UPDATE_IS_Lieferantentop5_CALLED(state: any, flag: boolean) {
    state.isLieferantentop5Called = flag;
  },
  UPDATE_IS_Materialdetail_CALLED(state: any, flag: boolean) {
    state.isMaterialdetailCalled = flag;
  },
  UPDATE_IS_Prev_Materialdetail_CALLED(state: any, flag: boolean) {
    state.isPrevMaterialdetailCalled = flag;
  },
  UPDATE_IS_Yearsums_CALLED(state: any, flag: boolean) {
    state.isYearsumsCalled = flag;
  },
  UPDATE_IS_Prev_Yearsums_CALLED(state: any, flag: boolean) {
    state.isPrevYearsumsCalled = flag;
  },
  UPDATE_IS_Bilanz_Editor_CALLED(state: any, flag: boolean) {
    state.isBilanzEditorCalled = flag;
  },
  UPDATE_IS_Plan_CALLED(state: any, flag: boolean) {
    state.isPlanCalled = flag;
  },
  UPDATE_IS_Kunden_CALLED(state: any, flag: boolean) {
    state.isKundenCalled = flag;
  },
  UPDATE_IS_Prev_Kunden_CALLED(state: any, flag: boolean) {
    state.isPrevKundenCalled = flag;
  },
  UPDATE_IS_Suppliers_CALLED(state: any, flag: boolean) {
    state.isSuppliersCalled = flag;
  },
  UPDATE_IS_Skr_Editor_CALLED(state: any, flag: boolean) {
    state.isSkrCalled = flag;
  },
  UPDATE_BWA_LINK_STATUS(state: any, data: any) {
    state.bwaLinkStatus = data;
  },
  UPDATE_IS_PLAN_SAVED(state: any, flag: boolean) {
    state.isPlanSaved = flag;
  },
  UPDATE_FOLDER_GUID(state: any, guid: any) {
    state.folderGuid = guid;
  },
  UPDATE_PERMISSIONS(state: any, permissions: any) {
    state.permissions = permissions;
  },
  UPDATE_USER_LEVEL(state: any, userLevel: any) {
    state.userLevel = userLevel;
  },
  UPDATE_VOURUS_URL(state: any, vourusUrl: any) {
    state.vourusUrl = vourusUrl;
  },
  UPDATE_MS_APP_ID(state: any, appId: any) {
    state.appId = appId;
  },
  UPDATE_LOGIN_TYPE(state: any, loginType: any) {
    state.loginType = loginType;
  },
  UPDATE_LIQUIDITAETSPLANUN_TABLE(state: any, table: any) {
    state.liquiditaetsplanunTable = table;
  },
};

export default mutations;
