/* eslint-disable no-console */
import Vue from "vue";
import { createOidcAuth, OidcAuth, SignInType } from "vue-oidc-client/vue2";
import {
  LogLevel as msalLogLevel,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";

import store from "@/store";
import api from "./api";

const loco = window.location;
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}externaldocuments`;

let mainOidcValue: OidcAuth;
var mainOidc = () => {
  if (mainOidcValue || store.state.vourusUrl === "") {
    return mainOidcValue;
  } else {
    mainOidcValue = createOidcAuth(
      "main",
      SignInType.Window,
      appRootUrl,
      {
        //authority: "https://vourus-staging.u-know.eu",
        authority: store.state.vourusUrl,
        client_id: "vourus-dms-test",
        response_type: "id_token token",
        scope: "openid",
        loadUserInfo: false,
        redirect_uri: `${loco.protocol}//${loco.host}${process.env.BASE_URL}loginredirect`,
        revokeAccessTokenOnSignout: true,
      },
      console
    );
    return mainOidcValue;
  }
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const loginRequest = () => {
  return {
    scopes: [store.state.appId + "/.default"],
  };
};

export const msalLogin = async () => {
  const msalInstance = createMsalInstance();
  const tokenResponse = await msalInstance.acquireTokenPopup(loginRequest());
  msalInstance.setActiveAccount(tokenResponse.account);
  localStorage.setItem("msAccount", JSON.stringify(tokenResponse.account));
  localStorage.setItem("msToken", JSON.stringify(tokenResponse.accessToken));
  localStorage.setItem(
    "msTokenExpires",
    tokenResponse.expiresOn!.getTime().toString() || ""
  );

  return tokenResponse.accessToken;
};

// export async function refreshToken() {
//   console.log("refereshtokeninner");

//   var token = msalInstance()
//     .acquireTokenSilent(loginRequest)
//     .catch((e) => console.log(e));
//   /*console.log("refereshtokeninner", token)
//   if(token != null){
//     return token.accessToken;
//   }
//   else {
//     //Todo: logout
//     return null
//   }*/
// }

export const createMsalInstance = () => {
  var msalConfig = {
    auth: {
      clientId: store.state.appId, // This is the ONLY mandatory field that you need to supply.
      authority: "https://login.microsoftonline.com/common/", // Defaults to "https://login.microsoftonline.com/common"
      redirectUri: `${loco.protocol}//${loco.host}${process.env.BASE_URL}login`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
      autoRefreshToken: true,
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msalLogLevel.Error:
              //console.error(message);
              return;
            case msalLogLevel.Info:
              //console.info(message);
              return;
            case msalLogLevel.Verbose:
              //console.debug(message);
              return;
            case msalLogLevel.Warning:
              //console.warn(message);
              return;
          }
        },
      },
    },
  };
  //console.log("Id while creating", msalConfig.auth.clientId);

  return new PublicClientApplication(msalConfig);
};

export async function refreshTokenJob() {
  const account = localStorage.getItem("msAccount");
  const msTokenExpires = localStorage.getItem("msTokenExpires");

  if (account !== null) {
    try {
      const tokenResponse = await createMsalInstance().acquireTokenSilent({
        ...loginRequest(),
        account: JSON.parse(account),
      });
      const auth = "Bearer " + tokenResponse.accessToken;
      localStorage.setItem(
        "msTokenExpires",
        tokenResponse.expiresOn!.getTime().toString() || ""
      );
      localStorage.setItem(
        "msToken",
        JSON.stringify(tokenResponse.accessToken)
      );
      api.setAuth(auth);
    } catch (error) {
      console.error(error);
      api.logout();
    }
  }

  if (msTokenExpires && parseInt(msTokenExpires) < Date.now()) {
    //localStorage.removeItem("msTokenExpires");
    location.reload();
  }

  setTimeout(() => {
    refreshTokenJob();
  }, 1000 * 60 * 55);
}

const checklogin = () => {
  const lastLogin = localStorage.getItem("lastLogin");

  if (lastLogin) {
    const dif = Date.now() - parseInt(lastLogin);
    const d = dif / (1000 * 3600 * 24);

    if (d >= 1) {
      api.login();
    }
  }
};

setTimeout(() => {
  checklogin();
}, 1000);

export const getAuthTypeText = (value?: number) => {
  switch (value) {
    case 2:
      return "Microsoft Sign in";
    case 3:
      return "Basic Auth und Microsoft Sign in";
    case 1:
    default:
      return "Basic Auth";
  }
};

Vue.prototype.$oidc = mainOidc;
export default mainOidc;
