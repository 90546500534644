import moment from "moment";
import "moment-timezone";
import store from "@/store";
import { LOC_NONE, LOC_MANUAL, LOC_SYSTEM } from "@/consts";
import { getRouter } from "@/router";
import api from "@/api";
import { hideLieferantenCharts, showLieferantenCharts } from "./charts";

export function am_UTC2Local(
  date: any,
  uselocale: any,
  with_secs: any,
  use_date: any,
  use_time: any
) {
  if (!date) {
    return;
  }
  if (typeof uselocale === "undefined" || uselocale === null) {
    uselocale = LOC_NONE;
  }
  if (typeof with_secs === "undefined" || with_secs === null) {
    with_secs = true;
  }
  if (typeof use_date === "undefined" || use_date === null) {
    use_date = true;
  }
  if (typeof use_time === "undefined" || use_time === null) {
    use_time = true;
  }

  // Make this string a valid ISO8601 string
  date = date.replace(" ", "T");
  date += "Z";

  function _ensure2(val: any) {
    return (val < 10 ? "0" + val : val).toString();
  }

  var u = new Date(
    moment(date)
      .tz("Europe/Berlin")
      .toString()
      .split("GMT")[0]
  );
  var rv = "";
  var avail_locs = ["en", "de"];

  // Fallback to LOC_NONE if LOC_MANUAL is desired but language not found
  if (
    uselocale == LOC_MANUAL &&
    avail_locs.indexOf(store.state.language) === -1
  ) {
    uselocale = LOC_NONE;
  }

  switch (uselocale) {
    case LOC_NONE:
      rv =
        u.getFullYear().toString() +
        "-" +
        _ensure2(u.getMonth() + 1) +
        "-" +
        _ensure2(u.getDate()) +
        " " +
        _ensure2(u.getHours()) +
        ":" +
        _ensure2(u.getMinutes()) +
        ":" +
        _ensure2(u.getSeconds());
      break;

    case LOC_SYSTEM:
      rv = u.toLocaleString();
      break;

    case LOC_MANUAL:
      switch (store.state.language) {
        case "en":
          if (use_date) {
            rv +=
              (u.getMonth() + 1).toString() +
              "/" +
              u.getDate().toString() +
              "/" +
              u.getFullYear().toString();
          }

          if (use_time) {
            if (use_date) {
              rv += " ";
            }

            var ampm = u.getHours() <= 12 ? "AM" : "PM";
            var hrs = u.getHours() - (u.getHours() > 12 ? 12 : 0);

            rv += hrs + ":" + _ensure2(u.getMinutes());

            if (with_secs) {
              rv += ":" + _ensure2(u.getSeconds());
            }

            rv += " " + ampm;
          }
          break;

        case "de":
          if (use_date) {
            rv =
              u.getFullYear().toString() +
              "-" +
              _ensure2(u.getMonth() + 1) +
              "-" +
              _ensure2(u.getDate());
          }
          if (use_time) {
            if (use_date) {
              rv += ", ";
            }
            rv += _ensure2(u.getHours()) + ":" + _ensure2(u.getMinutes());

            if (with_secs) {
              rv += ":" + _ensure2(u.getSeconds());
            }

            rv += " Uhr";
          }
          break;
      }
  }

  return rv;
}

export function getGermanNum(text: any) {
  return text.toString().replace(".", ",");
}

export function germanZahl(f: any) {
  return new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(f);
}

export function b64DecodeUnicode(str: any) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

export function isValidJSON(str: any) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export function b64EncodeUnicode(str: any) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      let val: any = "0x" + p1;
      return String.fromCharCode(val);
    })
  );
}

export function getRealNum(text: any) {
  return text
    .toString()
    .replace(/\./g, "")
    .replace(/,/g, ".");
}

export function getFormattedGermanNum(val: any) {
  return val.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function getFormattedNum(val: any) {
  return Math.abs(val) / 1000000 > 1
    ? getGermanNum((val / 1000000).toFixed(1)) + "M"
    : getGermanNum((val / 1000).toFixed(1)) + "k";
}

export function viewUmsatzKunden(client: any) {
  // localStorage.setItem('cur_umsatzdetails_kunden', JSON.stringify([client]));
  getRouter().replace("/kundenanalyse");
}

export function clearCallFlags() {
  store.dispatch("updateIsCalcCalled", false);
  store.dispatch("updateIsPrevCalcCalled", false);
  store.dispatch("updateIsLieferantentop5Called", false);
  store.dispatch("updateIsMaterialdetailCalled", false);
  store.dispatch("updateIsPrevMaterialdetailCalled", false);
  store.dispatch("updateIsYearsumsCalled", false);
  store.dispatch("updateIsPrevYearsumsCalled", false);
  store.dispatch("updateIsBilanzEditorCalled", false);
  store.dispatch("updateIsPlanCalled", false);
  store.dispatch("updateIsKundenCalled", false);
  store.dispatch("updateIsPrevKundenCalled", false);
  store.dispatch("updateIsSuppliersCalled", false);
}

export function getYears() {
  const client = store.getters.selectedClient;
  if (!client) {
    return;
  }
  if (getRouter().currentRoute.name === "liquiditaet") {
    store.dispatch("updateYears", [client[3][client[3].length - 1]]);
    store.dispatch("updateSelectedYear", client[3][client[3].length - 1]);
    return;
  } else {
    store.dispatch("updateYears", client[3]);
  }
  if (
    localStorage.getItem("year") &&
    client[3].findIndex(
      (y: any) => y[0] == JSON.parse(localStorage.getItem("year") || "")[0]
    ) > -1
  ) {
    localStorage.setItem(
      "year",
      JSON.stringify(
        client[3].find(
          (y: any) => y[0] == JSON.parse(localStorage.getItem("year") || "")[0]
        )
      )
    );
    store.dispatch(
      "updateSelectedYear",
      JSON.parse(localStorage.getItem("year") || "")
    );
    if (localStorage.getItem("planYear")) {
      store.dispatch(
        "updatePlanYear",
        JSON.parse(localStorage.getItem("planYear") || "")
      );
    } else {
      store.dispatch(
        "updatePlanYear",
        JSON.parse(localStorage.getItem("year") || "")[0]
      );
    }
  } else if (
    client[3].findIndex((y: any) => y[0] == new Date().getFullYear()) > -1
  ) {
    const year = client[3].find((y: any) => y[0] == new Date().getFullYear());
    store.dispatch("updateSelectedYear", year);
    localStorage.setItem("year", JSON.stringify(year));
    if (localStorage.getItem("planYear")) {
      store.dispatch(
        "updatePlanYear",
        JSON.parse(localStorage.getItem("planYear") || "")
      );
    } else {
      store.dispatch("updatePlanYear", year[0]);
    }
  } else {
    store.dispatch("updateSelectedYear", client[3][client[3].length - 1]);
    localStorage.setItem(
      "year",
      JSON.stringify(client[3][client[3].length - 1])
    );
    if (localStorage.getItem("planYear")) {
      store.dispatch(
        "updatePlanYear",
        JSON.parse(localStorage.getItem("planYear") || "")
      );
    } else {
      store.dispatch("updatePlanYear", client[3][client[3].length - 1][0]);
    }
  }
}

export function callCalc() {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsCalcCalled", true);
  api
    .getCalcData(client, year)
    .then((res) => {
      store.dispatch("updateCurrentCalcs", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updateCurrentCalcs", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function getFolderGuid() {
  const client = store.getters.selectedClient;
  if (!client) {
    return;
  }
  store.dispatch("updateLoading", store.state.loading + 1);
  api
    .getVourusMandators()
    .then((res) => {
      var mnumber = client[1];
      let mandant: any = Object.values(res.data)
        .flatMap((a) => a)
        .find((a: any) => a.mnumber == mnumber);
      store.dispatch("updateFolderGuid", mandant.guid);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callPrevCalc() {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;
  const years = store.getters.years;
  if (year[0] == years[0][0]) {
    store.dispatch("updatePreviousCalcs", null);
    return;
  }
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsPrevCalcCalled", true);
  api
    .getCalcData(client, [year[0] - 1])
    .then((res) => {
      store.dispatch("updatePreviousCalcs", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updatePreviousCalcs", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callLieferantentop5(params: any) {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsLieferantentop5Called", true);
  api
    .getLieferantenTop(client, year, params)
    .then((res) => {
      store.dispatch("updateLoading", store.state.loading - 1);
      store.dispatch("updateLieferantenTop", res.data);
    })
    .catch((err) => {
      store.dispatch("updateLieferantenTop", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callMaterialdetail(params: any) {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsMaterialdetailCalled", true);
  api
    .getMaterialDetail(client, year, params)
    .then((res) => {
      store.dispatch("updateMaterialDetail", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updateMaterialDetail", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callPrevMaterialdetail(params: any) {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsPrevMaterialdetailCalled", true);
  api
    .getPrevMaterialDetail(client, year, params)
    .then((res) => {
      store.dispatch("updatePrevMaterialDetail", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updatePrevMaterialDetail", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callYearsums() {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;

  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsYearsumsCalled", true);
  api
    .getYearSums(client, year)
    .then((res) => {
      store.dispatch("updateCurrentYearSums", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updateCurrentYearSums", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callPrevYearsums() {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;
  const years = store.getters.years;
  if (year[0] == years[0][0]) {
    store.dispatch("updatePreviousYearSums", null);
    return;
  }
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsPrevYearsumsCalled", true);
  api
    .getYearSums(client, [year[0] - 1])
    .then((res) => {
      store.dispatch("updatePreviousYearSums", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updatePreviousYearSums", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callBilanzEditor() {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;
  const clients = store.getters.clients;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsBilanzEditorCalled", true);
  switch (getRouter().currentRoute.name) {
    case "bilanz_editor_uknow":
      api
        .getUknowBilanzTemplates()
        .then((res) => {
          store.dispatch("updateBilanzTemplates", res.data);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplates", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
      break;
    case "bilanz_editor_kanzlei":
      api
        .getKanzleiBilanzTemplates(clients[0])
        .then((res) => {
          store.dispatch("updateBilanzTemplates", res.data);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplates", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
      break;
    case "bilanz_editor_mandant":
      api
        .getMandantTemplate(client, year)
        .then((res) => {
          if (res.data) {
            store.dispatch("updateBilanzTemplateData", res.data);
          } else {
            store.dispatch("updateBilanzTemplateData", []);
          }
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplateData", []);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
      break;
    case "guv_editor_admin":
      api
        .getGuvAdminTemplates()
        .then((res) => {
          store.dispatch("updateBilanzTemplates", res.data);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplates", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
      break;
    case "guv_editor_kanzlei":
      api
        .getGuvKanzleiTemplates(clients[0])
        .then((res) => {
          store.dispatch("updateBilanzTemplates", res.data);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplates", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
      break;
    case "guv_editor_mandant":
      api
        .getGuvMandant(client, year)
        .then((res) => {
          if (res.data) {
            store.dispatch("updateBilanzTemplateData", res.data);
          } else {
            store.dispatch("updateBilanzTemplateData", []);
          }
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateBilanzTemplateData", []);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
      break;
  }
}

export function callSkrEditor() {
  const client = store.getters.selectedClient;
  const year = store.getters.selectedYear;
  const clients = store.getters.clients;
  store.dispatch("updateIsSkrEditorCalled", true);
  store.dispatch("updateLoading", store.state.loading + 1);
  api
    .getUknowSkrTemplates()
    .then((res) => {
      store.dispatch("updateSkrTemplates", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updateSkrTemplates", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callPlan(year: any = null, updateBaseData = false) {
  const client = store.getters.selectedClient;
  const planYear = store.getters.planYear;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsPlanCalled", true);
  api
    .getPlanData(client, [year ? year : planYear])
    .then((res: any) => {
      let planData =
        store.getters.currentCalcs && store.getters.currentCalcs["BWA"];
      if (res.data) {
        planData = res.data.plan_month;
        store.dispatch("updatePlanVersion", res.data.version);
      } else {
        const l = planData["legend"];
        for (var i = 0; i < l.length; i++) {
          planData["total"][l[i][0]] = 0;
          for (var month = 1; month < 13; month++) {
            planData[month][l[i][0]] = 0;
          }
        }
      }
      let prevCalcs = store.getters.previousCalcs
        ? store.getters.previousCalcs
        : {};
      prevCalcs["BWA"] = planData;
      store.dispatch("updatePreviousCalcs", prevCalcs);
      store.dispatch("updatePlanData", planData);
      if (updateBaseData) {
        store.dispatch("updatePlanBaseData", planData);
      } else {
        if (res.data) {
          store.dispatch("updatePlanBaseData", res.data.baseData);
        }
      }
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updatePlanData", null);
      store.dispatch("updatePreviousCalcs", null);
      if (updateBaseData) {
        store.dispatch("updatePlanBaseData", null);
      }
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callKunden(params: any) {
  const year = store.getters.selectedYear;
  const client = store.getters.selectedClient;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsKundenCalled", true);
  api
    .getUmsDetailKunden(client, year, params)
    .then((res) => {
      store.dispatch("updateUmsDetailKunden", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updateUmsDetailKunden", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callPrevKunden(params: any) {
  const year = store.getters.selectedYear;
  const client = store.getters.selectedClient;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsPrevKundenCalled", true);
  api
    .getPrevUmsDetailKunden(client, year, params)
    .then((res) => {
      store.dispatch("updatePrevUmsDetailKunden", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updatePrevUmsDetailKunden", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function callSuppliers() {
  const year = store.getters.selectedYear;
  const client = store.getters.selectedClient;
  store.dispatch("updateLoading", store.state.loading + 1);
  store.dispatch("updateIsSuppliersCalled", true);
  api
    .getSuppliers(client, year)
    .then((res) => {
      store.dispatch("updateCurrentSuppliers", res.data);
      store.dispatch("updateLoading", store.state.loading - 1);
    })
    .catch((err) => {
      store.dispatch("updateCurrentSuppliers", null);
      store.dispatch("updateLoading", store.state.loading - 1);
    });
}

export function strSlugify(str: any) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export async function getVourusUrl() {
  let productionList = [
    "test.km-dashboard.de",
    "dashboard.u-know.eu",
    "charts.km-dashboard.de",
    "charts.kmmk.de",
  ];
  let isProduction = false;
  if (productionList.some((v) => location.href.includes(v))) {
    isProduction = true;
  }
  const res = await api.getVourusUrl().catch((err) => {
    store.dispatch("updateLoading", store.state.loading - 1);
    store.dispatch("updateVourusUrl", null);
  });
  if (res) {
    let vourUrl = res.data[0].dev;
    if (isProduction) {
      vourUrl = res.data[0].production;
    }
    store.dispatch("updateLoading", store.state.loading - 1);
    store.dispatch("updateVourusUrl", vourUrl);
  }
}

export async function getMsAppId() {
  await api
    .getMsAppId()
    .then((res) => {
      let appId = res.data;
      store.dispatch("updateLoading", store.state.loading - 1);
      store.dispatch("updateMsAppId", appId);
    })
    .catch((err) => {
      store.dispatch("updateLoading", store.state.loading - 1);
      store.dispatch("updateMsAppId", null);
    });
}

export function isEmailValid($email: string): boolean {
  if (
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test($email) &&
    $email == $email.toLowerCase()
  )
    return false;
  else return true;
}

export function setClientStorage(client: any) {
  localStorage.setItem("global_client", JSON.stringify(client));
}
export function getClientStorage() {
  if (localStorage.getItem("global_client")) {
    return JSON.parse(localStorage.getItem("global_client") || "");
  }
  return "";
}
export function setAgencyStorage(client: any) {
  localStorage.setItem("global_agency", client);
}
export function getAgencyStorage() {
  if (localStorage.getItem("global_agency")) {
    return localStorage.getItem("global_agency") || "";
  }
  return "";
}

export function nFormatter(n: any, decPlaces: any) {
  let number = Math.abs(n) as any;
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["k", "m", "b", "t"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  if (n < 0) {
    return "-" + number;
  } else {
    return number;
  }
}
