const state = {
  page: "bi",
  title: "",
  icon: "",
  loading: 0,
  resize: 0,
  themeType: null,
  dlayout: [
    {
      breakpoint: "lg",
      breakpointWidth: 1000,
      numberOfCols: 12,
      items: [] as any[],
    },
    {
      breakpoint: "xs",
      numberOfCols: 1,
      breakpointWidth: 0,
      items: [] as any[],
    },
  ],
  favorites: [],
  chartType: localStorage.getItem("chartType") || "bar",
  language: "de",
  rangeStep: "monthly",
  filterKunden: null,
  selectedYear: null,
  planYear: null,
  selectedClient: null,
  years: null,
  clients: null,
  currentCalcs: null,
  currentYearSums: null,
  previousCalcs: null,
  previousYearSums: null,
  currentSuppliers: null,
  umsDetailKunden: null,
  prevUmsDetailKunden: null,
  materialDetail: null,
  prevMaterialDetail: null,
  lieferantenTop: null,
  oposDeb: null,
  oposKred: null,
  oposFilters: null,
  oposCurves: null,
  bilanzTemplates: null,
  bilanzTemplateData: [],
  planSelected: false,
  planData: null,
  planBaseData: null,
  planVersion: null,
  skrTemplates: null,
  skrTemplateData: [],
  isCalcCalled: false,
  isPrevCalcCalled: false,
  isLieferantentop5Called: false,
  isMaterialdetailCalled: false,
  isPrevMaterialdetailCalled: false,
  isYearsumsCalled: false,
  isPrevYearsumsCalled: false,
  isBilanzEditorCalled: false,
  isPlanCalled: false,
  isKundenCalled: false,
  isPrevKundenCalled: false,
  isSuppliersCalled: false,
  isSkrCalled: false,
  bwaLinkStatus: null,
  isPlanSaved: true,
  folderGuid: null,
  permissions: null,
  userLevel: null,
  agencies: null,
  selectedAgency: null,
  selectedBiAgency: null,
  vourusUrl: "",
  appId: "",
  loginType: "",
  liquiditaetsplanunTable: { columns: [], items: [] },
};

export default state;
