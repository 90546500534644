import Vue from "vue";
import VueRouter from "vue-router";
import api from "@/api";
import store from "@/store";
import mainAuth from "@/auth";

Vue.use(VueRouter);

const createRoutes = (): any[] => {
  return [
    {
      path: "/",
      component: () => import("@/layouts/Main.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.path.includes("/einstellungen")) {
          store.dispatch("updatePage", "settings");
        } else {
          store.dispatch("updatePage", "bi");
        }
        changeTitleAndIcon();
        if (!location.pathname.includes("bilanz_editor_uknow")) {
          // save location path
          localStorage.setItem("path", JSON.stringify(to.path));

          if (!api.getAuth()) next("/login");
          else next();
        }
        next();
      },
      redirect: "/erfolgsrechnung",
      children: [
        {
          path: "/dashboard/:stb?/:mandant?/:year?/:range?/:comparison?",
          name: "dashboard",
          component: () => import("../views/main/Dashboard.vue"),
        },
        {
          path: "/bilanz/:mandant?/:year?",
          name: "bilanz",
          component: () => import("../views/main/Bilanz.vue"),
        },
        {
          path: "/guv",
          name: "guv",
          component: () => import("../views/main/Guv.vue"),
        },
        {
          path: "/erfolgsrechnung/:stb?/:mandant?/:year?/:range?/:comparison?",
          name: "erfolgsrechnung",
          component: () => import("../views/main/Erfolgsrechnung.vue"),
        },
        {
          path: "/opos/:stb?/:mandant?/:year?",
          name: "opos",
          component: () => import("../views/main/Opos.vue"),
        },
        {
          path: "/liquiditaetsplanung/:stb?/:mandant?/:year?",
          name: "liquiditaetsplanung",
          component: () => import("../views/main/Liquiditaetsplanung.vue"),
        },
        {
          path: "/buchungssaetze",
          name: "buchungssaetze",
          component: () => import("../views/main/Buchungssaetze.vue"),
        },
        {
          path: "/dokumente",
          name: "dokumente",
          component: () => import("../views/main/Dokumente.vue"),
        },
        {
          path: "/tokenDocs",
          name: "tokenDocs",
          meta: { authName: mainAuth().authName },
          component: () => import("../views/main/TokenDocs.vue"),
        },
        {
          path: "/umsatz/:stb?/:mandant?/:year?/:range?/:comparison?",
          name: "umsatz",
          component: () => import("../views/main/Umsatzanalyse.vue"),
        },
        {
          path: "/kundenanalyse/:stb?/:mandant?/:year?/:range?",
          name: "kundenanalyse",
          component: () => import("../views/main/Kundenanalyse.vue"),
        },
        {
          path: "/lieferantenanalyse/:stb?/:mandant?/:year?/:range?",
          name: "lieferantenanalyse",
          component: () => import("../views/main/Lieferantenanalyse.vue"),
        },
        {
          path: "/kosten/:stb?/:mandant?/:year?/:range?/:comparison?",
          name: "kosten",
          component: () => import("../views/main/Kostenanalyse.vue"),
        },
        {
          path: "/plan",
          name: "plan",
          component: () => import("../views/main/Plan.vue"),
        },
        {
          path: "/bilanz_editor_uknow",
          name: "bilanz_editor_uknow",
          component: () => import("../views/main/BilanzEditor.vue"),
        },
        {
          path: "/bilanz_editor_kanzlei",
          name: "bilanz_editor_kanzlei",
          component: () => import("../views/main/BilanzEditor.vue"),
        },
        {
          path: "/bilanz_editor_mandant",
          name: "bilanz_editor_mandant",
          component: () => import("../views/main/BilanzEditor.vue"),
        },
        {
          path: "/guv_editor_admin",
          name: "guv_editor_admin",
          component: () => import("../views/main/BilanzEditor.vue"),
        },
        {
          path: "/guv_editor_kanzlei",
          name: "guv_editor_kanzlei",
          component: () => import("../views/main/BilanzEditor.vue"),
        },
        {
          path: "/guv_editor_mandant",
          name: "guv_editor_mandant",
          component: () => import("../views/main/BilanzEditor.vue"),
        },
        {
          path: "/skr_editor_uknow",
          name: "skr_editor_uknow",
          component: () => import("../views/main/SKREditor.vue"),
        },
        {
          path: "/einstellungen/admin/skr_mandant/:stb?/:mandant?",
          name: "skr_mandant",
          component: () => import("../views/main/SKR.vue"),
        },
        // {
        //   path: '/einstellungen/admin/uknow_settings',
        //   name: 'uknow_settings',
        //   component: () => import('../views/settings/UknowSettings.vue')
        // },
        {
          path: "/einstellungen/admin/admins",
          name: "admins",
          component: () => import("../views/settings/UknowAdmins.vue"),
        },
        {
          path: "/einstellungen/admin/alle-benutzer",
          name: "alle-benutzer",
          component: () => import("../views/settings/UknowUsers.vue"),
        },
        {
          path: "/einstellungen/admin/alle-kanzleien",
          name: "alle-kanzleien",
          component: () => import("../views/settings/UknowAgencies.vue"),
        },
        {
          path: "/einstellungen/kanzlei/generell",
          name: "agency_settings",
          component: () => import("../views/settings/AgencySettings.vue"),
        },

        {
          path: "/einstellungen/kanzlei/mitarbeiter/:stb?",
          name: "agency_employees",
          component: () => import("../views/settings/AgencyEmployees.vue"),
        },
        {
          path: "/einstellungen/kanzlei/mandanten/:stb?",
          name: "agency_clients",
          component: () => import("../views/settings/AgencyClients.vue"),
        },
        {
          path: "/einstellungen/kanzlei/admins/:stb?",
          name: "agency_admins",
          component: () => import("../views/settings/AgencyAdmins.vue"),
        },
        {
          path: "/einstellungen/agency_management",
          name: "agency_management",
          component: () => import("../views/settings/AgencyManagement.vue"),
        },
        {
          path: "/einstellungen/mandant/client_settings",
          name: "client_settings",
          component: () => import("../views/settings/ClientManagment.vue"),
        },
        {
          path: "/einstellungen/mandant/assinged_agency_employees",
          name: "client_managment",
          component: () =>
            import("../views/settings/AssignAgentsEmployees.vue"),
        },
        {
          path: "/einstellungen/mandant/mitarbeiter/:stb?/:mandant?",
          name: "client_employees",
          component: () => import("../views/settings/ClientEmployees.vue"),
        },
        {
          path: "/einstellungen/mandant/alle-mandant-Viewer",
          name: "all_client_employees",
          component: () => import("../views/settings/AllClientEmployees.vue"),
        },
        {
          path: "/einstellungen/employee_management",
          name: "employee_management",
          component: () => import("../views/settings/EmployeeManagement.vue"),
        },
        {
          path: "/einstellungen/account_settings",
          name: "account_settings",
          component: () => import("../views/settings/AccountSettings.vue"),
        },
        {
          path: "/einstellungen/account_security",
          name: "account_security",
          component: () => import("../views/settings/AccountSecurity.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/ExternalContent.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        changeTitleAndIcon();
        next();
        /* if (!api.getAuth()) next()
          else next('/') */
      },
      children: [
        {
          path: "/externaldocuments",
          name: "external-documents",
          meta: { authName: mainAuth().authName },
          component: () => import("../views/external/ExternalShare.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/ExternalContent.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        changeTitleAndIcon();
        next();
        /* if (!api.getAuth()) next()
          else next('/') */
      },
      children: [
        {
          path: "/linkshare",
          name: "link-share",
          component: () => import("../views/external/LinkShare.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/ExternalContent.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        changeTitleAndIcon();

        if (!api.getAuth())
          next("/login?option=filemanager&share=" + to.query.share);
        else if (localStorage.getItem("msToken")) {
          window.location.replace(`/externaldocuments?share=` + to.query.share);
        } else next();
      },
      children: [
        {
          path: "/filemanager",
          name: "filemanager",
          component: () => import("../views/external/FileManagerBasic.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/NoAuth.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        changeTitleAndIcon();
        if (!api.getAuth()) next();
        else if (store.state.loginType == "ms") {
          //Go to ms externalshare if user is logged in with ms
          const loco = window.location;
          window.location.replace(
            `${loco.protocol}//${loco.host}${process.env.BASE_URL}externaldocuments?share=` +
              from.$route.query.share
          );
        } else next("/");
      },
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("../views/auth/Login.vue"),
        },
        {
          path: "/forgot-password",
          name: "forgot-password",
          component: () => import("../views/auth/ForgotPassword.vue"),
        },
        {
          path: "/resetpwd",
          name: "reset-password",
          component: () => import("../views/auth/ResetPassword.vue"),
        },
        {
          path: "/signup",
          name: "set-password",
          component: () => import("../views/auth/SetPassword.vue"),
        },
      ],
    },
    {
      path: "/einstellungen",
      redirect: "/einstellungen/admin/admins",
      // redirect: '/einstellungen/admin/uknow_settings'
    },
    {
      path: "*",
      beforeEnter: (to: any, from: any, next: any) => {
        changeTitleAndIcon();
        next();
      },
      component: () => import("@/layouts/404.vue"),
    },
  ];
};

const changeTitleAndIcon = () => {
  if (location.origin.indexOf("km-dashboard.de") > -1) {
    store.dispatch("updateTitle", "KMpro");
    store.dispatch("updateIcon", "/img/km-logo.png");
  } else {
    store.dispatch("updateTitle", "u-know");
    store.dispatch("updateIcon", "/img/uknow-logo.png");
  }
  document.title = store.getters.title;
  let link: any =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "favicon icon";
  link.href = store.getters.icon;
  document.getElementsByTagName("head")[0].appendChild(link);
};

let router: VueRouter;
export const getRouter = () => {
  if (router) {
    return router;
  } else {
    router = new VueRouter({
      routes: createRoutes(),
      mode: "history",
    });
    mainAuth().useRouter(router);
    return router;
  }
};
