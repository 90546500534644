













import Vue from "vue";
import store from "./store";
import Component from "vue-class-component";
import { VuePlugin } from "vuera";
import api from "./api";
import { Watch } from "vue-property-decorator";

Vue.use(VuePlugin);

@Component
export default class App extends Vue {
  isIframe = false;
  created() {
    //getMsAppId();

    window.addEventListener("resize", this.handleResize);
    //console.log('Vorous Url init')

    //getVourusUrl();

    if (window.location !== window.parent.location) {
      this.isIframe = true;
    }
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(e: any) {
    store.dispatch("updateResize", window.innerWidth);
  }

  get loading() {
    return store.getters.loading;
  }

  get userLevel() {
    return store.getters.userLevel;
  }

  @Watch("userLevel")
  loadSettings() {
    if (this.userLevel) {
      api.getUserSettings();
    }
  }
}
