const getters = {
  page: (state: any) => {
    return state.page;
  },
  title: (state: any) => {
    return state.title;
  },
  icon: (state: any) => {
    return state.icon;
  },
  loading: (state: any) => {
    return state.loading;
  },
  resize: (state: any) => {
    return state.resize;
  },
  themeType: (state: any) => {
    return state.themeType;
  },
  chartType: (state: any) => {
    return state.chartType;
  },
  dlayout: (state: any) => {
    return state.dlayout;
  },
  favorites: (state: any) => {
    return state.favorites;
  },

  rangeStep: (state: any) => {
    return state.rangeStep;
  },
  filterKunden: (state: any) => {
    return state.filterKunden;
  },
  selectedYear: (state: any) => {
    return state.selectedYear;
  },
  planYear: (state: any) => {
    return state.planYear;
  },
  selectedClient: (state: any) => {
    return state.selectedClient;
  },
  years: (state: any) => {
    return state.years;
  },
  clients: (state: any) => {
    return state.clients;
  },
  currentCalcs: (state: any) => {
    return state.currentCalcs;
  },
  currentYearSums: (state: any) => {
    return state.currentYearSums;
  },
  previousCalcs: (state: any) => {
    return state.previousCalcs;
  },
  previousYearSums: (state: any) => {
    return state.previousYearSums;
  },
  currentSuppliers: (state: any) => {
    return state.currentSuppliers;
  },
  umsDetailKunden: (state: any) => {
    return state.umsDetailKunden;
  },
  prevUmsDetailKunden: (state: any) => {
    return state.prevUmsDetailKunden;
  },
  materialDetail: (state: any) => {
    return state.materialDetail;
  },
  prevMaterialDetail: (state: any) => {
    return state.prevMaterialDetail;
  },
  lieferantenTop: (state: any) => {
    return state.lieferantenTop;
  },
  oposDeb: (state: any) => {
    return state.oposDeb;
  },
  oposKred: (state: any) => {
    return state.oposKred;
  },
  oposFilters: (state: any) => {
    return state.oposFilters;
  },
  oposCurves: (state: any) => {
    return state.oposCurves;
  },
  bilanzTemplates: (state: any) => {
    return state.bilanzTemplates;
  },
  bilanzTemplateData: (state: any) => {
    return state.bilanzTemplateData;
  },
  planSelected: (state: any) => {
    return state.planSelected;
  },
  planData: (state: any) => {
    return state.planData;
  },
  planBaseData: (state: any) => {
    return state.planBaseData;
  },
  planVersion: (state: any) => {
    return state.planVersion;
  },
  skrTemplates: (state: any) => {
    return state.skrTemplates;
  },
  skrTemplateData: (state: any) => {
    return state.skrTemplateData;
  },
  isCalcCalled: (state: any) => {
    return state.isCalcCalled;
  },
  isPrevCalcCalled: (state: any) => {
    return state.isPrevCalcCalled;
  },
  isLieferantentop5Called: (state: any) => {
    return state.isLieferantentop5Called;
  },
  isMaterialdetailCalled: (state: any) => {
    return state.isMaterialdetailCalled;
  },
  isPrevMaterialdetailCalled: (state: any) => {
    return state.isPrevMaterialdetailCalled;
  },
  isYearsumsCalled: (state: any) => {
    return state.isYearsumsCalled;
  },
  isPrevYearsumsCalled: (state: any) => {
    return state.isPrevYearsumsCalled;
  },
  isBilanzEditorCalled: (state: any) => {
    return state.isBilanzEditorCalled;
  },
  isPlanCalled: (state: any) => {
    return state.isPlanCalled;
  },
  isKundenCalled: (state: any) => {
    return state.isKundenCalled;
  },
  isPrevKundenCalled: (state: any) => {
    return state.isPrevKundenCalled;
  },
  isSuppliersCalled: (state: any) => {
    return state.isSuppliersCalled;
  },
  isSkrCalled: (state: any) => {
    return state.isSkrCalled;
  },
  bwaLinkStatus: (state: any) => {
    return state.bwaLinkStatus;
  },
  isPlanSaved: (state: any) => {
    return state.isPlanSaved;
  },
  folderGuid: (state: any) => {
    return state.folderGuid;
  },
  permissions: (state: any) => {
    return state.permissions;
  },
  userLevel: (state: any) => {
    return state.userLevel;
  },
  selectedAgency: (state: any) => {
    return state.selectedAgency;
  },
  agencies: (state: any) => {
    return state.agencies;
  },
  selectedBiAgency: (state: any) => {
    return state.selectedBiAgency;
  },
  loginType: (state: any) => {
    return state.loginType;
  },
  liquiditaetsplanunTable: (state: any) => {
    return state.liquiditaetsplanunTable;
  },
};

export default getters;
