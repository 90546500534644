export interface AppTheme {
  primary: string;
  primaryDark: string;
  primaryLight: string;
  primaryLighter: string;
  primaryLighter20: string;
  primaryLighter40: string;
  primaryLighter60: string;
  primaryLighter80: string;
}
export type ThemeType =
  | "default"
  | "mint"
  | "red"
  | "green"
  | "brown"
  | "yellow"
  | "pink";

const defaultTheme: AppTheme = {
  primary: "#6d6dc4",
  primaryDark: "#3c3b54",
  primaryLight: "#b5b5e1",
  primaryLighter: "#e1e1f3",
  primaryLighter20: "#8a8acf",
  primaryLighter40: "#a7a7db",
  primaryLighter60: "#c4c4e7",
  primaryLighter80: "#e1e1f3",
};

const mintTheme: AppTheme = {
  primary: "#7dbdc9",
  primaryDark: "#2f6873",
  primaryLight: "#bedde3",
  primaryLighter: "#e5f1f4",
  primaryLighter20: "#96cad3",
  primaryLighter40: "#b1d7de",
  primaryLighter60: "#cbe4e9",
  primaryLighter80: "#e5f1f4",
};

const redTheme: AppTheme = {
  primary: "#c62828",
  primaryDark: "#621414",
  primaryLight: "#e88d8d",
  primaryLighter: "#f5d1d1",
  primaryLighter20: "#da4949",
  primaryLighter40: "#e37777",
  primaryLighter60: "#eca4a4",
  primaryLighter80: "#f5d1d1",
};
const greenTheme: AppTheme = {
  primary: "#7dc989",
  primaryDark: "#2f733a",
  primaryLight: "#bee3c4",
  primaryLighter: "#e5f4e7",
  primaryLighter20: "#96d3a0",
  primaryLighter40: "#b1deb8",
  primaryLighter60: "#cbe9cf",
  primaryLighter80: "#e5f4e7",
};
const brownTheme: AppTheme = {
  primary: "#c9937d",
  primaryDark: "#73432f",
  primaryLight: "#e3c9be",
  primaryLighter: "#f4e9e5",
  primaryLighter20: "#d3a896",
  primaryLighter40: "#debeb1",
  primaryLighter60: "#e9d3cb",
  primaryLighter80: "#f4e9e5",
};
const yellowTheme: AppTheme = {
  primary: "#c9b97d",
  primaryDark: "#73642f",
  primaryLight: "#e3dbbe",
  primaryLighter: "#f4f1e5",
  primaryLighter20: "#d3c696",
  primaryLighter40: "#ded4b1",
  primaryLighter60: "#e9e3cb",
  primaryLighter80: "#f4f1e5",
};
const pinkTheme: AppTheme = {
  primary: "#ff66cc",
  primaryDark: "#b20077",
  primaryLight: "#ffb2e5",
  primaryLighter: "#ffe0f4",
  primaryLighter20: "#ff84d6",
  primaryLighter40: "#fea3e0",
  primaryLighter60: "#fec1ea",
  primaryLighter80: "#ffe0f4",
};

export function changeTheme(type: ThemeType) {
  const doc = document.documentElement;
  let theme: AppTheme;

  switch (type) {
    case "mint":
      theme = mintTheme;
      break;
    case "red":
      theme = redTheme;
      break;
    case "green":
      theme = greenTheme;
      break;
    case "brown":
      theme = brownTheme;
      break;
    case "yellow":
      theme = yellowTheme;
      break;
    case "pink":
      theme = pinkTheme;
      break;
    case "default":
    default:
      theme = defaultTheme;
      break;
  }

  doc.style.setProperty("--primary", theme.primary);
  doc.style.setProperty("--primary-dark", theme.primaryDark);
  doc.style.setProperty("--primary-light", theme.primaryLight);
  doc.style.setProperty("--primary-lighter", theme.primaryLighter);
  doc.style.setProperty("--primary-lighter-20", theme.primaryLighter20);
  doc.style.setProperty("--primary-lighter-40", theme.primaryLighter40);
  doc.style.setProperty("--primary-lighter-60", theme.primaryLighter60);
  doc.style.setProperty("--primary-lighter-80", theme.primaryLighter80);
  localStorage.setItem("themeType", type);
}

export function initTheme() {
  const type = localStorage.getItem("themeType");

  if (type) {
    changeTheme(type as ThemeType);
  }
}
