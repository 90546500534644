import { changeTheme } from "@/helper/themeUtils";

const actions = {
  updatePage(context: any, val: any) {
    context.commit("UPDATE_PAGE", val);
  },
  updateTitle(context: any, val: any) {
    context.commit("UPDATE_TITLE", val);
  },
  updateIcon(context: any, val: any) {
    context.commit("UPDATE_ICON", val);
  },
  updateLoading(context: any, val: any) {
    context.commit("UPDATE_LOADING", val);
  },
  updateThemeType(context: any, val: any) {
    changeTheme(val);
    context.commit("UPDATE_THEME", val);
  },
  updateChartType(context: any, val: any) {
    context.commit("UPDATE_CHART_TYPE", val);
  },
  updateDlayout(context: any, val: any) {
    context.commit("UPDATE_DLAYOUT", val);
  },
  updateFavorites(context: any, val: any) {
    context.commit("UPDATE_FAVORITES", val);
  },
  updateResize(context: any, val: any) {
    context.commit("UPDATE_RESIZE", val);
  },
  updateRangeStep(context: any, val: any) {
    context.commit("UPDATE_RANGE_STEP", val);
  },
  updateFilterKunden(context: any, val: any) {
    context.commit("UPDATE_FILTER_KUNDEN", val);
  },
  updateSelectedYear(context: any, year: any) {
    context.commit("UPDATE_SELECTED_YEAR", year);
  },
  updatePlanYear(context: any, year: any) {
    context.commit("UPDATE_PLAN_YEAR", year);
  },
  updateSelectedClient(context: any, client: any) {
    context.commit("UPDATE_SELECTED_CLIENT", client);
  },
  updateSelectedAgency(context: any, agency: any) {
    context.commit("UPDATE_SELECTED_AGENCY", agency);
  },
  updateBiSelectedAgency(context: any, agency: any) {
    context.commit("UPDATE_BI_SELECTED_AGENCY", agency);
  },
  updateYears(context: any, years: any) {
    context.commit("UPDATE_YEARS", years);
  },
  updateClients(context: any, clients: any) {
    context.commit("UPDATE_CLIENTS", clients);
  },
  updateAgencies(context: any, agencies: any) {
    context.commit("UPDATE_AGENCIES", agencies);
  },
  updateCurrentCalcs(context: any, calcs: any) {
    context.commit("UPDATE_CURRENT_CALCS", calcs);
  },
  updateCurrentYearSums(context: any, yearSums: any) {
    context.commit("UPDATE_CURRENT_YEAR_SUMS", yearSums);
  },
  updatePreviousCalcs(context: any, calcs: any) {
    context.commit("UPDATE_PREVIOUS_CALCS", calcs);
  },
  updatePreviousYearSums(context: any, yearSums: any) {
    context.commit("UPDATE_PREVIOUS_YEAR_SUMS", yearSums);
  },
  updateCurrentSuppliers(context: any, suppliers: any) {
    context.commit("UPDATE_CURRENT_SUPPLIERS", suppliers);
  },
  updateUmsDetailKunden(context: any, list: any) {
    context.commit("UPDATE_UMS_DETAIL_KUNDEN", list);
  },
  updatePrevUmsDetailKunden(context: any, list: any) {
    context.commit("UPDATE_PREV_UMS_DETAIL_KUNDEN", list);
  },
  updateMaterialDetail(context: any, list: any) {
    context.commit("UPDATE_MATERIAL_DETAIL", list);
  },
  updatePrevMaterialDetail(context: any, list: any) {
    context.commit("UPDATE_PREV_MATERIAL_DETAIL", list);
  },
  updateLieferantenTop(context: any, list: any) {
    context.commit("UPDATE_LIEFERANTEN_TOP", list);
  },
  updateOposDeb(context: any, data: any) {
    context.commit("UPDATE_OPOS_DEB", data);
  },
  updateOposKred(context: any, data: any) {
    context.commit("UPDATE_OPOS_KRED", data);
  },
  updateOposFilters(context: any, data: any) {
    context.commit("UPDATE_OPOS_FILTERS", data);
  },
  updateOposCurves(context: any, data: any) {
    context.commit("UPDATE_OPOS_CURVES", data);
  },
  updateBilanzTemplates(context: any, data: any) {
    context.commit("UPDATE_BILANZ_TEMPLATES", data);
  },
  updateBilanzTemplateData(context: any, data: any) {
    context.commit("UPDATE_BILANZ_TEMPLATE_DATA", data);
  },
  updatePlanSelected(context: any, data: any) {
    context.commit("UPDATE_PLAN_SELECTED", data);
  },
  updatePlanData(context: any, data: any) {
    context.commit("UPDATE_PLAN_DATA", data);
  },
  updatePlanBaseData(context: any, data: any) {
    context.commit("UPDATE_PLAN_BASE_DATA", data);
  },
  updatePlanVersion(context: any, data: any) {
    context.commit("UPDATE_PLAN_VERSION", data);
  },
  updateSkrTemplates(context: any, data: any) {
    context.commit("UPDATE_SKR_TEMPLATES", data);
  },
  updateSkrTemplateData(context: any, data: any) {
    context.commit("UPDATE_SKR_TEMPLATE_DATA", data);
  },
  updateIsCalcCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Calc_CALLED", flag);
  },
  updateIsPrevCalcCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Prev_Calc_CALLED", flag);
  },
  updateIsLieferantentop5Called(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Lieferantentop5_CALLED", flag);
  },
  updateIsMaterialdetailCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Materialdetail_CALLED", flag);
  },
  updateIsPrevMaterialdetailCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Prev_Materialdetail_CALLED", flag);
  },
  updateIsYearsumsCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Yearsums_CALLED", flag);
  },
  updateIsPrevYearsumsCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Prev_Yearsums_CALLED", flag);
  },
  updateIsBilanzEditorCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Bilanz_Editor_CALLED", flag);
  },
  updateIsPlanCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Plan_CALLED", flag);
  },
  updateIsKundenCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Kunden_CALLED", flag);
  },
  updateIsPrevKundenCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Prev_Kunden_CALLED", flag);
  },
  updateIsSuppliersCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Suppliers_CALLED", flag);
  },
  updateIsSkrEditorCalled(context: any, flag: boolean) {
    context.commit("UPDATE_IS_Skr_Editor_CALLED", flag);
  },
  updateBwaLinkStatus(context: any, data: any) {
    context.commit("UPDATE_BWA_LINK_STATUS", data);
  },
  updateIsPlanSaved(context: any, flag: boolean) {
    context.commit("UPDATE_IS_PLAN_SAVED", flag);
  },
  updateFolderGuid(context: any, guid: any) {
    context.commit("UPDATE_FOLDER_GUID", guid);
  },
  updatePermissions(context: any, permissions: any) {
    context.commit("UPDATE_PERMISSIONS", permissions);
  },
  updateUserLevel(context: any, userLevel: any) {
    context.commit("UPDATE_USER_LEVEL", userLevel);
  },
  updateVourusUrl(context: any, vourusUrl: any) {
    context.commit("UPDATE_VOURUS_URL", vourusUrl);
  },
  updateMsAppId(context: any, appId: any) {
    context.commit("UPDATE_MS_APP_ID", appId);
  },
  updateLoginType(context: any, loginType: any) {
    context.commit("UPDATE_LOGIN_TYPE", loginType);
  },
  updateLiquiditaetsplanunTable(context: any, table: any) {
    context.commit("UPDATE_LIQUIDITAETSPLANUN_TABLE", table);
  },
};

export default actions;
