import axios from "axios";
import { getRouter } from "./router";
import { default as msalPlugin } from "@azure/msal-browser";
//import { refreshToken } from "./auth";
import store from "@/store";

const API = "/api";

export default {
  // setRequestInterceptor() {
  //     axios.interceptors.request.use(
  //          config =>  {
  //             console.log('logintype', store.state.loginType)
  //             if(store.state.loginType == 'ms') {
  //                 console.log('start ms login')

  //                 return refreshToken().then((token) => {
  //                     config.headers['Authorization'] = 'Bearer ' + token;
  //                     return Promise.resolve(config);
  //                 })
  //             }
  //             return config;
  //         },
  //         error => {
  //             Promise.reject(error)
  //         });
  // },

  getAuth() {
    return localStorage.getItem("auth");
  },
  setAuth(auth: string) {
    localStorage.setItem("auth", auth);
    axios.defaults.headers.common["Authorization"] = auth;
  },
  async login() {
    const result = await axios.get(API + "/checklogin");
    localStorage.setItem("lastLogin", Date.now().toString());
    return result;
  },
  forgotPassword(email: string) {
    return axios.post(API + "/forgotpwd", {
      email,
    });
  },
  resetPassword(code: any, password: string) {
    return axios.post(API + "/setforgotpwd", {
      code,
      password,
    });
  },
  getClients() {
    return axios.get(API + "/mandatoryears");
  },
  getVourusMandators() {
    return axios.get(API + "/vourusmandators");
  },
  getMandatorstbs() {
    return axios.get(API + "/mandatorstbs");
  },
  logout() {
    store.dispatch("updateLoginType", "");
    localStorage.clear();
    //Delete VOURUS DMS Cookie
    document.cookie = "dmsToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    getRouter().replace("/login");
    // getRouter().replace('/login')
    location.reload();
  },
  getCalcData(client: any, year: any) {
    return axios.get(
      API + "/calc/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  getYearSums(client: any, year: any) {
    return axios.get(
      API + "/yearsums/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  getSuppliers(client: any, year: any) {
    return axios.post(
      API + "/supplier_list/" + client[0] + "/" + client[1] + "/" + year[0],
      {}
    );
  },
  getUmsDetailKunden(client: any, year: any, params: any) {
    return axios.post(
      API + "/umsdetailkd/" + client[0] + "/" + client[1] + "/" + year[0],
      params
    );
  },
  getPrevUmsDetailKunden(client: any, year: any, params: any) {
    return axios.post(
      API +
        "/umsdetailkdprevyear/" +
        client[0] +
        "/" +
        client[1] +
        "/" +
        year[0],
      params
    );
  },
  getMaterialDetail(client: any, year: any, params: any) {
    return axios.post(
      API + "/materialdetail/" + client[0] + "/" + client[1] + "/" + year[0],
      params
    );
  },
  getPrevMaterialDetail(client: any, year: any, params: any) {
    return axios.post(
      API +
        "/materialdetail_prevyear/" +
        client[0] +
        "/" +
        client[1] +
        "/" +
        year[0],
      params
    );
  },
  getLieferantenTop(client: any, year: any, params: any) {
    return axios.post(
      API + "/lieferantentop5/" + client[0] + "/" + client[1] + "/" + year[0],
      params
    );
  },
  getBwaKonto(client: any, year: any, code: any) {
    return axios.get(
      API +
        "/bwa2kto/" +
        client[0] +
        "/" +
        client[1] +
        "/" +
        year[0] +
        "/" +
        code
    );
  },
  getDrilldownBwa(client: any, year: any, code: any) {
    return axios.get(
      API +
        "/drilldownbwa/" +
        client[0] +
        "/" +
        client[1] +
        "/" +
        year[0] +
        "/" +
        code
    );
  },
  getDrilldownBwaChildren(client: any, year: any, code: any) {
    return axios.post(
      API +
        "/drilldownbwachildren/" +
        client[0] +
        "/" +
        client[1] +
        "/" +
        year[0],
      code
    );
  },
  getOposDeb(client: any, year: any) {
    return axios.get(
      API +
        "/oposmahnstufen/" +
        client[0] +
        "/" +
        client[1] +
        "/" +
        year[0] +
        "/" +
        "DEB"
    );
  },
  getOposKred(client: any, year: any) {
    return axios.get(
      API +
        "/oposmahnstufen/" +
        client[0] +
        "/" +
        client[1] +
        "/" +
        year[0] +
        "/" +
        "KRED"
    );
  },
  getOposFilters(client: any, year: any) {
    return axios.get(
      API + "/oposfilters/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  getOposCurves(client: any, year: any) {
    return axios.get(
      API + "/oposcurves/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  getUknowBilanzTemplates() {
    return axios.get(API + "/uknowbilanz");
  },
  getUknowTemplate(name: any) {
    return axios.get(API + "/uknowbilanz/" + name);
  },
  saveUknowBilanz(name: any, json: any) {
    return axios.post(API + "/uknowbilanz/" + name, json);
  },
  deleteUknowBilanz(name: any) {
    return axios.delete(API + "/uknowbilanz/" + name);
  },
  getKanzleiBilanzTemplates(client: any) {
    return axios.get(API + "/genbilanz/" + client[0]);
  },
  getKanzleiTemplate(client: any, name: any) {
    return axios.get(API + "/genbilanz/" + client[0] + "/" + name);
  },
  deleteKanzleiBilanz(client: any, name: any) {
    return axios.delete(API + "/genbilanz/" + client[0] + "/" + name);
  },
  saveKanzleiBilanz(client: any, name: any, json: any) {
    return axios.post(API + "/genbilanz/" + client[0] + "/" + name, json);
  },
  getMandantTemplate(client: any, year: any) {
    return axios.get(
      API + "/mandbilanz/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  deleteMandantBilanz(client: any, year: any) {
    return axios.delete(
      API + "/mandbilanz/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  saveMandantBilanz(client: any, year: any, json: any) {
    return axios.post(
      API + "/mandbilanz/" + client[0] + "/" + client[1] + "/" + year[0],
      json
    );
  },
  getBuchungssaetze(client: any, year: any, params: any) {
    return axios.post(
      API + "/buchungssaetze/" + client[0] + "/" + client[1] + "/" + year[0],
      params
    );
  },
  getBuchungssaetzeFilter(client: any, year: any, params: any) {
    return axios.post(
      API +
        "/buchungssaetze_filters/" +
        client[0] +
        "/" +
        client[1] +
        "/" +
        year[0],
      params
    );
  },
  getOposTableData(client: any, year: any, params: any) {
    return axios.post(
      API + "/opos/" + client[0] + "/" + client[1] + "/" + year[0],
      params
    );
  },
  getOposTableFilter(client: any, year: any) {
    return axios.get(
      API + "/oposfilters/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  getPlanData(client: any, year: any) {
    return axios.get(
      API + "/plandata/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  getBwaData(client: any, year: any) {
    return axios.get(
      API + "/calc/" + client[0] + "/" + client[1] + "/" + year + "/BWA"
    );
  },
  savePlanData(client: any, year: any, data: any) {
    return axios.post(
      API + "/plandata/" + client[0] + "/" + client[1] + "/" + year[0],
      data
    );
  },
  getGuvAdminTemplates() {
    return axios.get(API + "/uknowguv");
  },
  getGuvAdminTemplate(name: any) {
    return axios.get(API + "/uknowguv/" + name);
  },
  saveGuvAdmin(name: any, json: any) {
    return axios.post(API + "/uknowguv/" + name, json);
  },
  deleteGuvAdmin(name: any) {
    return axios.delete(API + "/uknowguv/" + name);
  },
  getGuvMandant(client: any, year: any) {
    return axios.get(
      API + "/mandguv/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  deleteGuvMandant(client: any, year: any) {
    return axios.delete(
      API + "/mandguv/" + client[0] + "/" + client[1] + "/" + year[0]
    );
  },
  saveGuvMandant(client: any, year: any, json: any) {
    return axios.post(
      API + "/mandguv/" + client[0] + "/" + client[1] + "/" + year[0],
      json
    );
  },
  getGuvKanzleiTemplates(client: any) {
    return axios.get(API + "/genguv/" + client[0]);
  },
  getGuvKanzleiTemplate(client: any, name: any) {
    return axios.get(API + "/genguv/" + client[0] + "/" + name);
  },
  deleteGuvKanzlei(client: any, name: any) {
    return axios.delete(API + "/genguv/" + client[0] + "/" + name);
  },
  saveGuvKanzlei(client: any, name: any, json: any) {
    return axios.post(API + "/genguv/" + client[0] + "/" + name, json);
  },
  getUknowSkrTemplates() {
    return axios.get(API + "/uknowskr");
  },
  getUknowSkrTemplate(name: string) {
    return axios.get(API + "/uknowskr/" + name);
  },
  saveUknowSkr(name: any, json: any) {
    return axios.post(API + "/uknowskr/" + name, json);
  },
  deleteUknowSkr(name: any) {
    return axios.delete(API + "/uknowskr/" + name);
  },
  getMandantSkrs(client: any) {
    return axios.get(API + "/mandskr/" + client[0] + "/" + client[1]);
  },
  getMandantSkr(client: any, year: any) {
    return axios.get(
      API + "/mandskr/" + client[0] + "/" + client[1] + "/" + year
    );
  },
  deleteMandantSkr(client: any, year: any) {
    return axios.delete(
      API + "/mandskr/" + client[0] + "/" + client[1] + "/" + year
    );
  },
  saveMandantSkr(client: any, year: any, name: string) {
    return axios.post(
      API + "/mandskr/" + client[0] + "/" + client[1] + "/" + year,
      { name }
    );
  },
  getPermissions() {
    return axios.get(API + "/mypermissions");
  },
  getMyLevel() {
    return axios.get(API + "/mylevel");
  },
  getUsers() {
    return axios.get(API + "/cclistusers");
  },
  postUser(user: any) {
    return axios.post(API + "/ccadduser", user);
  },
  updateUser(userId: any, user: any) {
    return axios.post(API + "/ccedituser/" + userId, user);
  },
  deleteUser(userId: string) {
    return axios.delete(API + "/ccdeluser/" + userId);
  },
  getStbs() {
    return axios.get(API + "/ccliststbs");
  },
  getStb(stbId: any) {
    return axios.get(API + "/ccreadstb/" + stbId);
  },
  postStb(stb: any) {
    return axios.post(API + "/ccaddstb", stb);
  },
  updateStb(stbId: any, stb: any) {
    return axios.post(API + "/cceditstb/" + stbId, stb);
  },
  deleteStb(stbId: string) {
    return axios.delete(API + "/ccdelstb/" + stbId);
  },
  toggleStb(stbId: any, active: boolean) {
    return axios.post(API + "/ccactivestb/" + stbId, { active });
  },
  getMandators(stbId: string) {
    return axios.get(API + "/cclistmands/" + stbId);
  },
  getMandator(stbId: string, manId: string) {
    return axios.get(API + "/ccreadmand/" + stbId + "/" + manId);
  },
  postMandator(stbId: string, mandator: any) {
    return axios.post(API + "/ccaddmand/" + stbId, mandator);
  },
  updateMandator(stbId: any, manId: string, mandator: any) {
    return axios.post(API + "/cceditmand/" + stbId + "/" + manId, mandator);
  },
  deleteMandator(stbId: string, manId: string) {
    return axios.delete(API + "/ccdelmand/" + stbId + "/" + manId);
  },
  toggleMandator(stbId: any, manId: string, active: boolean) {
    return axios.post(API + "/ccactivemand/" + stbId + "/" + manId, { active });
  },
  getOwnStbs() {
    return axios.get(API + "/cclistownstbs");
  },
  getOwnStb(stbId: string) {
    return axios.get(API + "/ccreadownstb/" + stbId);
  },
  updateOwnStb(stbId: string, stb: any) {
    return axios.post(API + "/cceditownstb/" + stbId, stb);
  },
  getEmployees(stbId: string) {
    return axios.get(API + "/cclistemployees/" + stbId);
  },
  getEmployeesViewer(stbId: string) {
    return axios.get(API + "/ccstbusermands/" + stbId);
  },
  postEmployee(stbId: string, employee: any) {
    return axios.post(API + "/ccaddemployee/" + stbId, employee);
  },
  postAdmin(stbId: string, employee: any) {
    return axios.post(API + "/spawnstbsibling/" + stbId, employee);
  },
  deleteEmployee(stbId: string, empId: string) {
    return axios.delete(API + "/ccdelemployee/" + stbId + "/" + empId);
  },
  getEmployeeMandators(stbId: string, empId: string) {
    return axios.get(API + "/ccempmandators/" + stbId + "/" + empId);
  },
  getMandatorEmployees(stbId: string, manId: string) {
    return axios.get(API + "/ccmandatoremps/" + stbId + "/" + manId);
  },
  linkEmployeeMandator(stbId: string, manId: string, empId: string) {
    return axios.post(
      API + "/ccempmandlink/" + stbId + "/" + manId + "/" + empId
    );
  },
  unlinkEmployeeMandator(stbId: string, manId: string, empId: string) {
    return axios.post(
      API + "/ccempmandunlink/" + stbId + "/" + manId + "/" + empId
    );
  },
  getStbUsers(stbId: string) {
    return axios.get(API + "/ccstbusers/" + stbId);
  },
  getUserStbs(userId: string) {
    return axios.get(API + "/ccuserstbs/" + userId);
  },
  getConfigClientMandators() {
    return axios.get(API + "/configclientmandators");
  },
  getSkrmandyears(stbId: string, empId: string) {
    return axios.get(API + "/skrmandyears/" + stbId + "/" + empId);
  },
  downloadExportProgram(stbId: string) {
    return axios.get(API + "/exe/" + stbId, { responseType: "arraybuffer" });
  },
  setGeneratedPasswordUsers(userId: string) {
    return axios.post(API + "/cctriggerpwdset/" + userId);
  },
  getVourusUrl() {
    return axios.get("/vourusConfig.json");
  },
  getMsAppId() {
    return axios.get(API + "/auth365appid");
  },
  batchLinkEmployeesToMandators(stb: string, mandators: any) {
    return axios.post(API + "/ccbatchempmandlink/" + stb, mandators);
  },
  batchUnlinkEmployeesToMandators(stb: string, mandators: any) {
    return axios.post(API + "/ccbatchempmandunlink/" + stb, mandators);
  },

  async getCallerInfo() {
    return (await axios.get(API + "/cccallerinfo")).data;
  },

  async getAllStbs() {
    return axios.get(API + "/ccallstbs");
  },

  async getUserSettings() {
    const settings = (await axios.get(API + "/userconfig")).data;

    if (settings) {
      const chartType = settings["chartType"];
      const themeType = settings["themeType"];
      const favorites = settings["favorites"];
      const dlayout = settings["dlayout"];

      if (chartType) {
        store.dispatch("updateChartType", chartType);
      }
      if (themeType) {
        store.dispatch("updateThemeType", themeType);
      }
      if (favorites) {
        store.dispatch("updateFavorites", favorites);
      }
      if (dlayout) {
        store.dispatch("updateDlayout", dlayout);
      }
    }
  },

  async saveUserSettings() {
    axios.post(API + "/userconfig", {
      themeType: store.getters.themeType,
      chartType: store.getters.chartType,
      dlayout: store.getters.dlayout,
      favorites: store.getters.favorites,
    });
  },

  async getLiquiditaetsplanung(stbId: string, mandant: string, year: string) {
    return (await axios.get(`${API}/mandliq/${stbId}/${mandant}/${year}`)).data;
  },

  updateLiquiditaetsplanung(
    stb: string,
    mandant: string,
    year: string,
    data: any
  ) {
    return axios.post(`${API}/mandliq/${stb}/${mandant}/${year}`, data);
  },
};
