export const Signs = {
  1020: "+",
  1040: "+",
  1045: "+",
  1051: "=",
  1060: "-",
  1080: "=",
  1090: "+",
  1092: "=",
  1280: "=",
  1100: "-",
  1120: "-",
  1140: "-",
  1150: "-",
  1160: "-",
  1180: "-",
  1200: "-",
  1220: "-",
  1240: "-",
  1250: "-",
  1260: "-",
  1300: "=",
  1310: "-",
  1312: "-",
  1320: "-",
  1322: "+",
  1323: "+",
  1330: "+",
  1345: "=",
  1355: "+",
  1380: "=",
  EBITDA: "=",
};

// Temporary drilldown implementation of BWA table
// https://dtsystems.atlassian.net/wiki/spaces/UK/pages/13238291/Formulas+for+value+calculation
// @TODO: This should be fetched dynamically using editor (including calculation rules) in the future
// Format: [status, indentation, show, parent, fetched]
// Status:
// > = Expandable
// v = Collapsed
// x = No children
export const BwaTreeNodes: any = {
  1020: [">", 0, true, null],
  1040: [">", 0, true, null],
  1045: [">", 0, true, null],
  1051: ["x", 0, true, null], // 1020 + 1040 + 1045
  1060: [">", 0, true, null],
  1080: ["x", 0, true, null], // 1051 - 1060
  1090: [">", 0, true, null],
  1092: ["x", 0, true, null], // 1080 + 1090
  1100: [">", 0, true, null],
  1120: [">", 0, true, null],
  1140: [">", 0, true, null],
  1150: [">", 0, true, null],
  1160: [">", 0, true, null],
  1180: [">", 0, true, null],
  1200: [">", 0, true, null],
  1220: [">", 0, true, null],
  1240: [">", 0, true, null],
  1250: [">", 0, true, null],
  1260: [">", 0, true, null],
  1280: ["x", 0, true, null], // 1100 + 1120 + 1140 + 1150 + 1160 + 1180 + 1200 + 1220 + 1240 + 1250 + 1260s
  1300: ["x", 0, true, null], // 1092 - 1080
  1310: [">", 0, true, null],
  1312: [">", 0, true, null],
  1320: ["x", 0, true, null], // 1310 + 1312
  1322: [">", 0, true, null],
  1323: [">", 0, true, null],
  1324: ["x", 0, true, null],
  1330: ["x", 0, true, null], // 1322 + 1323 + 1324
  1340: ["x", 0, true, null],
  1345: ["x", 0, true, null], // 1300 + 1330 - 1320
  1355: [">", 0, true, null],
  1380: ["x", 0, true, null], // 1345 - 1355
  EBITDA: ["x", 0, true, null], // 1380 + 1240
};

export const Months = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
];

export const BwaSummaryTable: any = {
  1020: ["redirect", "umsatz"],
  1040: ["redirect_filter", "buchungssaetze"],
  1045: ["redirect_filter", "buchungssaetze"],
  1051: ["redirect_filter", "buchungssaetze"],
  1060: ["anchor", "total_purchase_goods"],
  1080: ["redirect_filter", "buchungssaetze"],
  1090: ["redirect_filter", "buchungssaetze"],
  1092: ["redirect_filter", "buchungssaetze"],
  1100: ["redirect_filter", "buchungssaetze"],
  1120: ["redirect_filter", "buchungssaetze"],
  1140: ["redirect_filter", "buchungssaetze"],
  1150: ["redirect_filter", "buchungssaetze"],
  1160: ["redirect_filter", "buchungssaetze"],
  1180: ["redirect_filter", "buchungssaetze"],
  1200: ["redirect_filter", "buchungssaetze"],
  1220: ["redirect_filter", "buchungssaetze"],
  1240: ["redirect_filter", "buchungssaetze"],
  1250: ["redirect_filter", "buchungssaetze"],
  1260: ["redirect_filter", "buchungssaetze"],
  1280: ["redirect", "kostenanalyse"],
  1300: ["anchor", "operating_profit"],
  1310: ["redirect_filter", "buchungssaetze"],
  1312: ["redirect_filter", "buchungssaetze"],
  1320: ["redirect_filter", "buchungssaetze"],
  1322: ["redirect_filter", "buchungssaetze"],
  1323: ["redirect_filter", "buchungssaetze"],
  1324: ["redirect_filter", "buchungssaetze"],
  1330: ["redirect_filter", "buchungssaetze"],
  1340: ["redirect_filter", "buchungssaetze"],
  1345: ["redirect_filter", "buchungssaetze"],
  1355: ["redirect_filter", "buchungssaetze"],
  1380: ["anchor", "EBIT"],
  EBITDA: ["anchor", "EBITDA"],
};

export const LOC_NONE = 0; // Use ISO
export const LOC_SYSTEM = 1; // Use system's locale
export const LOC_MANUAL = 2; // Manual rendering

export const MultiTitle = "Mehrfachauswahl";

export const PlanRules = [
  {
    id: 1051,
    plus: [1020, 1040, 1045],
  },
  {
    id: 1080,
    plus: [1051],
    minus: [1060],
  },
  {
    id: 1092,
    plus: [1080, 1090],
  },
  {
    id: 1280,
    plus: [1100, 1120, 1140, 1150, 1160, 1180, 1200, 1220, 1240, 1250, 1260],
  },
  {
    id: 1300,
    plus: [1092],
    minus: [1280],
  },
  {
    id: 1320,
    plus: [1310, 1312],
  },
  {
    id: 1330,
    plus: [1322, 1323, 1324],
  },
  {
    id: 1345,
    plus: [1300, 1330],
    minus: [1320],
  },
  {
    id: 1380,
    plus: [1345],
    minus: [1355],
  },
  {
    id: "EBITDA",
    plus: [1380, 1240],
  },
];
