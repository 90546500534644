import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { getRouter } from "./router";
import store from "./store";
import { initTheme } from "./helper/themeUtils";
import axios from "axios";
import VueAxios from "vue-axios";
import mainAuth, { refreshTokenJob } from "./auth";
import MdButton from "vue-material/dist/components/MdButton";
import MdAvatar from "vue-material/dist/components/MdAvatar";
import MdTooltip from "vue-material/dist/components/MdTooltip";
import MdDialog from "vue-material/dist/components/MdDialog";

import MdIcon from "vue-material/dist/components/MdIcon";

import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

axios.defaults.headers.common["APILevel"] = 1;
if (localStorage.getItem("auth")) {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("auth");
}

initTheme();

Vue.use(MdButton);
Vue.use(MdIcon);
Vue.use(MdAvatar);
Vue.use(MdTooltip);
Vue.use(MdDialog);

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

const appIdRequest = getMsAppId();
const vourUrlRequest = getVourusUrl();

Promise.all([appIdRequest, vourUrlRequest]).then(() => {
  refreshTokenJob();
  mainAuth()
    .startup()
    .then((ok: any) => {
      if (ok) {
        new Vue({
          router: getRouter(),
          store,
          render: (h) => h(App),
        }).$mount("#app");
      }
    });
});

import "@/assets/theme/css/style.bundle.css";
import "@/assets/scss/style.scss";
import { getMsAppId, getVourusUrl } from "./helper/utils";
